@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1a191c;
}

html,
body {
  @apply min-h-full;
}

@media only screen and (max-width: 768px) {
  body {
    width: fit-content;
  }
}

select {
  background-color: #1a191c;
  @apply text-zinc-100;
}

.active {
  @apply text-orange-500 font-bold border-b-2 border-orange-500;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  @apply bg-gray-800 shadow-lg;
}

::-moz-scrollbar {
  width: 4px;
}

::-moz-scrollbar-thumb {
  @apply bg-gray-800 shadow-lg;
}
.bg-primary {
  background-color: #1a191c;
}

.bg-secondary {
  background-color: #2b2930;
}

.btn {
  @apply text-sm px-3 py-2 rounded font-bold;
}

.btn.primary {
  @apply text-neutral-300 border border-orange-900;
}

.btn.primary:hover,
.btn.primary.filled:hover {
  @apply bg-orange-700;
}

.btn.large {
  @apply text-base;
}

.btn.primary.filled {
  @apply bg-orange-600 text-zinc-100;
}
